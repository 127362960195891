<template>
<cube-popup type="my-popup" ref="extendPopup" :zIndex='1000000'>
  <div id="aqt_confirm">
    <div class="aqt_confirm_center">
      <div class="aqt_confirm_title">
        <i class="cubeic-info"></i>
        <p class="aqt_lang_mn titlefont">{{'ᠰᠠᠨᠠᠭᠤᠯᠬᠤ ᠦᠭᠡ'|U2M}}</p>
      </div>
      <!-- <div class="aqt_input titlefont aqt_lang_mn" v-if="IsInput">
        <input ref="inputt" type="text" v-model="num" :placeholder="U2M('请输入馆藏码')">
      </div> -->

      <div class="aqt_confirm_con" >
        <p class="aqt_lang_mn contentfont">{{message|U2M}}</p>
      </div>
      <div class="aqt_confirm_con1" v-show="InputErr">
        <p class="aqt_lang_mn contentfont">{{messagess|U2M}}</p>
      </div>
      <div class="aqt_confirm_buton" v-if="!isAlert">
        <div class="but ok" @click="onOk">
          <p class="aqt_lang_mn titlefont">{{'ᠲᠡᠭᠡᠶ᠎ᠡ'|U2M}}</p>
        </div>
        <div class="but cancel" @click="onCancel" v-if="showClose">
          <p class="aqt_lang_mn titlefont">{{'ᠬᠠᠰᠤᠬᠤ'|U2M}}</p>
        </div>
      </div>
      <div class="aqt_confirm_buton" v-if="isAlert">
        <div class="but"></div>
        <div class="but cancel" @click="onCancel">
          <p class="aqt_lang_mn titlefont">{{'ᠬᠠᠰᠤᠬᠤ'|U2M}}</p>
        </div>
      </div>

    </div>

  </div>
</cube-popup>
</template>

<script>
export default {
  props: {
    isAlert: {
      type: [Boolean],
      default: false,
    },
    IsShow: {
      type: [Boolean],
      default: false,
    },
    // IsInput: {
    //   type: [Boolean],
    //   default: false,
    // },
    showClose: {
      type: [Boolean],
      default: true,
    },
    message: {
      type: [String],
      default: '',
    },
    //  num: {
    //   type: [Number],
    //   default: null,
    // },
  },
  data() {
    return {
      messagess: '馆藏码错误',
      InputErr: false,
      num: '',
    }
  },
  methods: {
    onOk() {
      const that = this
      this.$emit('on-ok');
      this.$refs.extendPopup.hide()
    },
    onCancel() {
      this.$emit('isClose', false);
      this.$refs.extendPopup.hide()
    },
    oneBuy() {
      this.$emit('one-buy');
      this.$refs.extendPopup.hide()
    },
    onesBuy() {
      this.$emit('ones-buy');
      this.$refs.extendPopup.hide()
    },
  },
  computed: {

  },
  mounted() {
    // this.$refs.extendPopup.show() 
  },
  watch: {
    IsShow: function (val) {
      if (this.IsShow) {
        this.$refs.extendPopup.show()
      } else {
        this.$refs.extendPopup.hide()
      }
    },
  }
}
</script>

<style lang="less" >
.cube-popup-mask{
    background-color: #25262d  !important;
    opacity: .4 !important;
}
#aqt_confirm {
 
  width: 100vh;
  height: 50vh;
  overflow: hidden;
  writing-mode: horizontal-tb;
  color: #333333;
  
  .aqt_confirm_center {
    position: absolute;
    right: 4rem;
    left: 4rem;
    height: 100%;
    display: flex;
    font-size: 2rem;
    background-color: #ffffff;
    border-radius: 1rem;
 
    .aqt_confirm_title {
      width: 4rem;
      height: 100%;
      text-align: center;
      border-right: 1px solid #eeeeee;
      padding-top: 1rem;

      p {
        margin: .2rem auto 0;
      }
    }

    .aqt_confirm_con {
      width: calc(100% - 10rem);
      height: 100%;
      border-right: 1px solid #eeeeee;
      padding: 1rem;
    }

    .aqt_confirm_con1 {
      // width: calc(100% - 10rem);
      height: 100%;
      color: red;
      border-right: 1px solid #eeeeee;
      padding: 1rem;
    }

    .aqt_input {
      //   width: calc(100% - 10rem);
      height: 100%;
      padding: 1rem;
      width: 6rem;
      height: 100%;
      margin: 0 auto;
      //   margin-left: .8rem;

      input {
        border: 1px solid #ccc;
        width: 100%;
        height: 100%;
        display: table-cell;
        color: #000000;
        text-indent: 1rem;
        border-radius: .5rem;
      }

      input.aqt_time {
        height: 48%;
      }
    }

    .aqt_confirm_con_but {
      width: calc(100% - 10rem);
      height: 100%;
      border-right: 1px solid #eeeeee;
      padding: 1rem;
      font-size: 1.3rem;
      overflow-x: auto;

      .but {
        // width: 2rem;
        padding: .8rem;
        height: calc(50% - 1rem);
        border-radius: .5rem;
        border: 1px solid #003461;
        color: #003461;
        margin-left: .8rem;

        p {
          margin: 0 auto;
        }
      }
    }

    .aqt_confirm_buton {
      width: 6rem;
      height: 100%;

      .but {
        width: 60%;
        height: 40%;
        color: #ffffff;
        border-radius: 1rem;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        display: -ms-flexbox;
        display: flex;
        margin: 30% auto;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin: auto;
        }
      }

      .ok {
        background-color: var(--themeColor);
      }

      .cancel {
        background-color: #EDEDED;
      }
    }
  }

}
</style>
